/* Fonts */
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

/* 3rd */
// @import '~ngx-red-zoom/styles/base.scss';
// @import '~ngx-red-zoom/styles/style-magnifier.scss';

/* Custom CSS */
@import "./assets/styles/cards.scss";
@import "./assets/styles/misc.scss";
@import "./assets/styles/bs5.scss";
/* @import "./assets/styles/switch-slider.css"; */
@import "./assets/styles/mat.scss";
@import "./assets/styles/dashboard.scss";
@import "./assets/styles/chat.scss";
@import "./assets/styles/nav-menu.scss";
// @import "./contas.scss";
// @import "./integradoras.scss";
@import "./pedidos.scss";

/* Importing Bootstrap SCSS file. */
// @import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
// @import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

/* misc */
* {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
}

body {
  // margin: 0px;
  // height: 0px;
  // box-sizing: border-box;
  background-color: whitesmoke;
  // min-height: 100vh;
  // width: 100vw;
}

.box {
  background-color: white;
  min-height: 100vh;
  width: 100%;
  border-radius: 20px;
  padding: 2rem;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.toggle-wrapper {
  width: 180px;
  // background-color: red;
}

/* banners */
.produto-imgs .owl-stage-outer {
  position: relative;
}

.produto-imgs .owl-prev,
.produto-imgs .owl-next {
  width: 110px;
  height: 110px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  background: transparent !important;
  transition: .3s all ease;
}

.produto-imgs .owl-prev i,
.produto-imgs .owl-next i {
  color: white;
  // text-shadow: 2px 2px #666666;
  font-size: 25px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.produto-imgs .owl-prev {
  left: 50px;
}

.produto-imgs .owl-next {
  right: -60px;
}

.produto-imgs .owl-prev i {
  left: 25px;
}

.produto-imgs .owl-next i {
  right: -15px;
}

.produto-imgs .owl-prev:hover::before,
.produto-imgs .owl-next:hover::before {
  background-color: #ffffff50;
  // background-color: var(--ion-color-primary-tint) !important;
  // opacity: .5;
}

.produto-imgs .owl-prev::before,
.produto-imgs .owl-next::before {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 110px;
  height: 110px;
  background-color: #ffffff50;
  content: '';
  border-radius: 50%;
}

.produto-imgs .owl-prev::before {
  left: 0px;
}

.produto-imgs .owl-next::before {
  right: -112px;
}
