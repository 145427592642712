/* ===== MENU ===== */
.nav-menu {
  float: left;
  // height: 100%;
  width: 70px;
  background: var(--ion-color-primary-tint);
  // background: #4768b5;
  // background: -webkit-linear-gradient(#4768b5, #35488e);
  // background: -o-linear-gradient(#4768b5, #35488e);
  // background: -moz-linear-gradient(#4768b5, #35488e);
  // background: linear-gradient(#4768b5, #35488e);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19);
  border-radius: 8px;
}

.nav-menu:hover {
  width: 140px;
}

.nav-menu .items {
  list-style: none;
  margin: auto;
  padding: 0;
}

.nav-menu .items .item {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ion-color-primary-contrast);
  // color: #9fb5ef;
  font-size: 17pt;
}

.nav-menu .items .item .caption {
  font-size: 60%;
  opacity: .6;
  display: none;
}

.nav-menu:hover .items .item .caption {
  display: block;
}

.nav-menu .items .item:first-child {
  border-radius: 8px 8px 0px 0px;
}

.nav-menu .items .item:last-child {
  border-radius: 0px 0px 8px 8px;
}

.nav-menu .items .item:not(:last-child) {
  border-bottom: 1px solid var(--ion-color-primary-shade);
}

.nav-menu .items .item-active {
  background-color: var(--ion-color-primary);
  // background-color: #5172c3;
  color: var(--ion-color-primary-contrast);
  // color: #FFF;
}

.nav-menu .items .item:hover {
  animation: wiggle (2.5s forwards);
  cursor: pointer;
  // background-color: var(--ion-color-primary);
  opacity: .5;
  // background-color: #4f6ebd;
  color: var(--ion-color-primary-contrast);
  // color: #cfe5ff;
}