.chat {
    // width: calc(65% - 85px);
    width: 100%;
  }
  
  .chat .messages-chat {
    padding: 25px 35px;
  }
  
  .chat .messages-chat .message {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .chat .messages-chat .message .photo {
    position: absolute;
    left: -45px;
    top: 50%;
    transform: translateY(-50%);
    // display: block;
    width: 45px;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .chat .messages-chat .text {
    margin: 0 35px;
    // background-color: #f6f6f6;
    background-color: #e6f6e6;
    padding: 15px;
    border-radius: 12px;
  }
  
  .text-only {
    margin-left: 45px;
  }
  
  .time {
    font-size: 12px;
    color: gray;
    margin-bottom: 12px;
    margin-left: 45px;
  }
  
  .response-time {
    // background-color: red;
    // float: right;
    margin-left: auto;
    text-align: right;
    // margin-top: 10px;
    margin-right: 40px !important;
    // margin-bottom: 10px;
    // margin-right: 0px !important;
  }
  
  .response {
    text-align: right;
    // float: right;
    margin-left: auto;
    margin-right: 0px !important;
    /* flexbox alignment rule */
  }
  
  .response .text {
    background-color: #e3effd !important;
  }
  
  // https://codepen.io/ThomasDaubenton/pen/QMqaBN
  