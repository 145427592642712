.dashboard .cells {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  // justify-items: center;
  // justify-items: stretch;
  grid-gap: 4px;
  text-align: center;
  list-style-type: none;
  padding: 6px;
  // background-color: blue;
}

.dashboard .cell {
  // text-align: center;
  // min-height: 100px;
  background-color: whitesmoke;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #eee;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
  pointer-events: all;
}

.dashboard .cell .icon {
  color: silver; // var(--ion-color-primary-tint);
  position: absolute;
  bottom: 0px;
  right: 0px;
  opacity: 50%;
}

.dashboard .cell h6 {
  color: #999;
  font-size: 0.8;
}

.dashboard .cell h1 {
  font-size: 3rem;
  color: #666;
}

.dashboard .cells.cell.wiggle {
  border: 1px solid rgb(252, 147, 128);
}

.dashboard .cells.cell.wiggle * {
  font-weight: bold;
  color: tomato;
}

.dashboard .cells.cell.wiggle .icon {
  opacity: 40%
}
